import React, { useContext } from 'react';
import Header from './cmp/Header';
import { I18nContext } from '../../store/I18nStore';
import { Link } from 'react-router-dom';



function Faq() {
  const { i18n, getT, scheme } = useContext(I18nContext);


  const styles = {
    header: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '20px',
      letterSpacing: '-0.24px',
    },
    section: {
      fontFamily: 'Inter',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: '-0.24px',
    },
    link: {
      color: scheme === 'dark' ? '#866ADC' : '#6E4CD9',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '-0.24px',
      padding: '5px 0',
    },
  };

  if (i18n === null) {
    return null;
  }

  function getLang(){
    return window.location.href.indexOf('/ru') > 10 ? 'ru' : 'en';
  }

  return (
    <>
      <Header />
      <div style={{
        display: 'flex',
        padding: '15px',/* Выравнивание по вертикали */
        justifyContent: 'flex-start', /* Выравнивание по горизонтали от начала */
      }}>
        <div>
          <div style={{...styles.header}}>{getT('page_title_navigation')}</div>

          <br/>
          <div style={{...styles.section}}> {getT('navigation_section_account')}</div>
          <div style={{...styles.link}}>
            <Link to={'login/' + getLang()}>&bull;&nbsp;{getT('page_title_login')}</Link>
          </div>
          <div style={{...styles.link}}>
            <Link to={'inactivity/' + getLang()}>&bull;&nbsp;{getT('page_title_inactivity')}</Link>
          </div>

          <br/>
          <div style={{...styles.section}}> {getT('navigation_section_tokens')}</div>
          <div style={{...styles.link}}>
            <Link to={'howEarn/' + getLang()}>&bull;&nbsp;{getT('page_title_how_earn')}</Link>
          </div>
          <div style={{...styles.link}}>
            <Link to={'marketplace/' + getLang()}>&bull;&nbsp;{getT('page_title_marketplace')}</Link>
          </div>

          <br/>
          <div style={{...styles.section}}> {getT('navigation_section_buffs')}</div>
          <div style={{...styles.link}}>
            <Link to={'buff_tutorial/' + getLang()}>&bull;&nbsp;{getT('page_title_buffs')}</Link>
          </div>
          <div style={{...styles.link}}>
            <Link to={'buff_getting/' + getLang()}>&bull;&nbsp;{getT('page_title_buff_getting')}</Link>
          </div>
          <div style={{...styles.link}}>
            <Link to={'buff_shattering/' + getLang()}>&bull;&nbsp;{getT('page_title_shatter')}</Link>
          </div>

          <br/>
          <div style={{...styles.section}}> {getT('navigation_section_tennis')}</div>
          <div style={{...styles.link}}>
            <Link to={'ranking_atp/' + getLang()}>&bull;&nbsp;{getT('page_title_tennis_ranking_atp')}</Link>
          </div>
          <div style={{...styles.link}}>
            <Link to={'ranking_wta/' + getLang()}>&bull;&nbsp;{getT('page_title_tennis_ranking_wta')}</Link>
          </div>
          <div style={{...styles.link}}>
            <Link to={'tennis_doubles/' + getLang()}>&bull;&nbsp;{getT('page_title_tennis_doubles')}</Link>
          </div>

          <br/>
          <div style={{...styles.section}}> {getT('navigation_section_tournaments')}</div>
          <div style={{...styles.link}}>
            <Link to={'tournaments_list/' + getLang()}>&bull;&nbsp;{getT('page_title_tournaments_list')}</Link>
          </div>
          <div style={{...styles.link}}>
            <Link to={'tournaments_exit/' + getLang()}>&bull;&nbsp;{getT('page_title_team_tournaments_exit')}</Link>
          </div>
          <div style={{...styles.link}}>
            <Link to={'fanclubs_exit/' + getLang()}>&bull;&nbsp;{getT('page_title_fanclubs_exit')}</Link>
          </div>

          <br/>
          <div style={{...styles.section}}> {getT('navigation_section_general_info')}</div>
          <div style={{...styles.link}}>
            <Link to={'release_notes/' + getLang()}>&bull;&nbsp;{getT('page_title_release_notes')}</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faq;
